//@ts-ignore
import { V2AggregateLoader } from '@terragotech/form-renderer';
import LabelWrapper, {
  TGLabelWrapperProps,
} from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageLabelWrapper';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import MapItemsContainer from '../../containers/MapItemsContainer';
import { MapAssetType } from '../../contexts/AggregatesContext/types';
import { Symbol } from '../../components/Legend';
import { gql, useApolloClient } from '@apollo/client';
import { useAggregates } from '../../contexts/AggregatesContext';
import { useFilter } from '../../contexts/FilterContext/filterContext';
import _ from 'lodash';
import { useAssetCards } from '../../contexts/assetCardContext';
import { useSelectedProject } from '../../contexts/selectedProjectContext';

export type TGMapFieldProps = TGLabelWrapperProps & {
  value?: any;
  aggregates: V2AggregateLoader[];
  height?: string;
  symbols?: Symbol[];
  includeWMS?: boolean;
  latSplit?: number;
  lonSplit?: number;
  limitPerSection?: number;
  showLocationSearch?: boolean;
  hideVisibility?: boolean;
  mapParams?: any;
  setMapAssets?: Dispatch<SetStateAction<MapAssetType[]>>;
  mapBounds?: { minLat: number; minLon: number; maxLat: number; maxLon: number } | null;
  setMapBounds?: (input:any)=>void;
};

const SUBMIT_REFRESH_TIME = 2 * 1000; //2 seconds

const TGPageMapField: React.FC<TGMapFieldProps> = props => {
  const { aggregates: inputAgg, setMapAssets, mapBounds, setMapBounds } = props;
  const client = useApolloClient();
  const { mapFilterState, drawFilter } = useFilter();
  const isMapExtentEnabled = mapFilterState.extentFilterEnabled;
  const { updateAllAssets } = useAggregates();
  const [assets, setAssets] = useState<MapAssetType[]>([]);
  const { assetIds } = useAssetCards();
  const assetIdsRef = useRef(assetIds);
  const [reloadTrigger, setReloadTrigger] = useState<number>(0);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [aggregates, setAggregates] = useState(inputAgg);
  const [mapBoundsLocal, setMapBoundsLocal] = useState<{ minLat: number; minLon: number; maxLat: number; maxLon: number } | null>(
    null
  );
  const {selectedProjects} = useSelectedProject()
  const currentLoad = useRef<number>(0);
  const maxLimitRef = React.useRef<number | undefined>(undefined);
  useEffect(() => {
    if (JSON.stringify(aggregates) !== JSON.stringify(inputAgg)) {
      setAggregates(inputAgg);
    }
  }, [inputAgg]);

  useEffect(() => {
    assetIdsRef.current = assetIds;
  }, [assetIds]);

  const getMaxLimit = (propsLimit: number | undefined) => {
    if (isMapExtentEnabled || (drawFilter.apply && !_.isEmpty(drawFilter.features))) {
      return maxLimitRef.current;
    }
    return propsLimit;
  };

  const checkDoesClusterhasIdt = (fetchedAssets: MapAssetType[]) => {
    if (assetIdsRef.current?.length) {
      const hasCommonId = fetchedAssets.some(asset => assetIdsRef.current.includes(asset.id));
      if (!hasCommonId && props.mapParams.setSelectedClusterId && props.mapParams.handleMultipleCardsClose) {
        props.mapParams.setSelectedClusterId(0);
        props.mapParams.handleMultipleCardsClose();
      }
    }
  };

  const updateBoundedAssets = useCallback(
    (loadCount: number, fetchedAssets: MapAssetType[]) => {
      if (loadCount === currentLoad.current) {
        checkDoesClusterhasIdt(fetchedAssets);
        setIsDataLoading(false);
        if (isMapExtentEnabled || (drawFilter.apply && !_.isEmpty(drawFilter.features))) {
          updateAllAssets(fetchedAssets);
        }
        setMapAssets && setMapAssets(fetchedAssets);
        setAssets(fetchedAssets);
      }
    },
    [setIsDataLoading, isMapExtentEnabled, drawFilter, updateAllAssets, setAssets]
  );

  useEffect(() => {
    const bounds = mapBounds || mapBoundsLocal;
    if (bounds) {
      const thisLoad = ++currentLoad.current;
      if (aggregates.length === 0 || !selectedProjects.length) {
        updateBoundedAssets(thisLoad, []);
        return;
      }
      const maxCountInCluster = _.maxBy(
        assets.filter(asset => asset.count),
        'count'
      );
      if (maxCountInCluster) {
        maxLimitRef.current = Number(maxCountInCluster?.count) + 1;
      }
      setIsDataLoading(true);
      client
        .query<{ aggregateBounds: MapAssetType[] }>({
          query: gql`
            query aggregateBounds(
              $queryDef: JSON!
              $minLon: Float!
              $maxLon: Float!
              $minLat: Float!
              $maxLat: Float!
              $latSplit: Float
              $lonSplit: Float
              $limitPerSection: Float
              $value: String
              $values: [String]
            ) {
              aggregateBounds(
                filter: {
                  minLat: $minLat
                  minLon: $minLon
                  maxLat: $maxLat
                  maxLon: $maxLon
                  queryDef: $queryDef
                  value: $value
                  values: $values
                  latSplit: $latSplit
                  lonSplit: $lonSplit
                  limitPerSection: $limitPerSection
                }
              )
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            ...bounds,
            queryDef: aggregates,
            value: props.value?.value,
            values: props.value?.values,
            latSplit: props.latSplit,
            lonSplit: props.lonSplit,
            limitPerSection: getMaxLimit(props.limitPerSection),
          },
        })
        .then(result => updateBoundedAssets(thisLoad, result.data.aggregateBounds))
        .catch(e => {
          console.log('fetch error', e);
        });
    }
  }, [mapBounds, mapBoundsLocal, reloadTrigger, aggregates, drawFilter, isMapExtentEnabled]);

  const handleFormSubmit = useCallback(() => {
    setTimeout(() => {
      setReloadTrigger(p => p + 1);
    }, SUBMIT_REFRESH_TIME);
  }, []);
  const MAP_HEIGHT = 400;
  const rootStyles = {
    height: Number(props.height) || props.height || MAP_HEIGHT,
    width: '100%',
    position: 'relative',
    overflow: props.height ? '' : 'hidden',
  };
  return (
    <>
      <LabelWrapper required={props.required} readOnly={true} label={props.label} info={props.info} oneLine={false} />
      <div style={rootStyles as React.CSSProperties}>
        <MapItemsContainer
          onFormSubmit={handleFormSubmit}
          zoom={props.value?.zoom}
          lat={props.value?.lat}
          lon={props.value?.lon}
          symbols={props.symbols}
          assets={assets}
          includeWMS={props.includeWMS}
          setMapBounds={setMapBounds || setMapBoundsLocal}
          showLoading={isDataLoading}
          showLocationSearch={props.showLocationSearch}
          mapParams={props.mapParams}
          hideVisibility={props.hideVisibility}
        ></MapItemsContainer>
      </div>
    </>
  );
};

export default TGPageMapField;
