import { gql } from '@apollo/client';
import { pascalCase } from 'change-case';
import { AggregateDefinition, getAggregateDataFields } from '@terragotech/gen5-shared-components';

// eslint-disable-next-line import/no-anonymous-default-export
export default (aggregateDefinition: AggregateDefinition) => gql`query assetsQuery($filters: ${pascalCase(
  aggregateDefinition.name
)}Filter, $first: Int, $after: Cursor, $orderBy: [${pascalCase(
  aggregateDefinition.plural
)}OrderBy!] , $last: Int, $before: Cursor, $conditions: ${pascalCase(aggregateDefinition.name)}Condition){
    ${aggregateDefinition.queryKey}: ${
  aggregateDefinition.queryKey
}Connection(filter: $filters, first: $first, after: $after, orderBy: $orderBy, last: $last, before: $before, condition: $conditions) {
      edges {
        node{
          ${getAggregateDataFields(aggregateDefinition,undefined,undefined,undefined,true)}
          id
          label
          symbolKey
          primaryLocation {
            geojson
          }
        }
        cursor
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }`;

export const FilterQuery = (
  aggregateDefinition: AggregateDefinition,
  field: string,
  fieldType: string,
  limitValue?: number,
  offsetValue?: number,
  searchValue?: string
) => {
  const tableName = aggregateDefinition.queryKey;
  const fieldDef = aggregateDefinition.propertyDefinitions.find(def => def.field === field);
  const limit = limitValue ?? null;
  const offset = offsetValue ?? null;
  const searchText = searchValue ? searchValue : '';
  if (fieldDef?.isRelationship) {
    return gql`query MyQuery($selectedProjects: [String!]!){
          distinctValues(
            filter: {tableName: "${tableName}", columnName: "${fieldDef.type.toLowerCase()}s.label"}
            join: {tableName: "${fieldDef.type.toLowerCase()}s", columnName: "id", parentColumnName: "${field}"}
            project: {columnName: "project", values: $selectedProjects}
            limit: ${limit}
            offset: ${offset}
            searchText: "${searchText}"
            fieldType: "${fieldType}"
          )
        }`;
  } else {
    return gql`query MyQuery ($selectedProjects: [String!]!) {
          distinctValues(
            filter: {tableName: "${tableName}", columnName: "${field}"}
            project: {columnName: "project", values: $selectedProjects}
            limit: ${limit}
            offset: ${offset}
            searchText: "${searchText}"
            fieldType: "${fieldType}"
          )
        }`;
  }
};

export const RecordTypeAssetCountQuery = (aggregateDefinition: AggregateDefinition) => {
  return gql`query assetsQuery($filters: ${pascalCase(aggregateDefinition.name)}Filter){
        ${aggregateDefinition.queryKey}: ${aggregateDefinition.queryKey}Connection(filter: $filters) {
         totalCount
      }}`;
};

export const createAssetQueryWithFilter = (
  aggregateDefinition: AggregateDefinition,
  selectedProjects: string[],
  searchText: string
) => {
  const projectList = JSON.stringify(selectedProjects);
  return `
        ${aggregateDefinition.queryKey}Connection(
          filter: { or:  {${
            aggregateDefinition.labelProperty
          }: {includesInsensitive: "${searchText}"}},  projectId: { in: ${projectList}}}

        ) {
          edges {
            node{
              ${getAggregateDataFields(aggregateDefinition)}
              id
              label
              symbolKey
              primaryLocation {
                geojson
              }
            }
          }
        }
      `;
};

export const GlobalSearchQueryCreator = (
  aggregateDefinitions: AggregateDefinition[],
  selectedProjects: string[],
  searchText: string
) => {
  const queryParts = aggregateDefinitions
    .map(def => createAssetQueryWithFilter(def, selectedProjects, searchText))
    .join('\n');
  const queryString = `query MyQuery {
    ${queryParts}
  }`;
  return gql`
    ${queryString}
  `;
};
