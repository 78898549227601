import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopSearchBar from './TopSearchBar';
import { FitBounds } from "react-mapbox-gl/lib/map";

interface MapControlsOverlayProps {
  setBounds: (bounds: FitBounds | undefined) => void;
  setVisibilityModal: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  mapContainerRef: HTMLDivElement | null;
  hideVisibility?: boolean;
}
const MapSearchControlOverlay: FunctionComponent<MapControlsOverlayProps> = props => {
  const { setBounds, setVisibilityModal, isModalOpen, mapContainerRef, hideVisibility } = props;
  const classes = useStyles();
  return (
    <div className={classes.mapCenterControls}>
      <div className={classes.mapHeader}>
        <TopSearchBar
          setBounds={setBounds}
          setVisibilityModal={setVisibilityModal}
          isModalOpen={isModalOpen}
          mapContainerRef={mapContainerRef}
          hideVisibility={hideVisibility}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapCenterControls: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 23,
    display: 'flex',
  },
  mapHeader: {
    marginTop: 26,
    top: 0,
    zIndex: 1000,
    position: 'absolute',
    justifyContent: 'center',
  },
}));

export default MapSearchControlOverlay;
